<template lang="pug">
TheBook(
	:title="factories.title"
	:endpoint="factories.endpoint"
	:path="factories.path"
	:left="factories.left"
	:leftSub="factories.leftSub"
)
</template>

<script>
import TheBook from '../components/TheBook'
import BOOKS from '../modules/constants/books'

export default {
	name: 'Factories',
	components: { TheBook },
	setup () {
		const { factories } = BOOKS

		return {
			factories
		}
	}
}
</script>
